import React from "react"

export const Book = () => {
  return (
    <div id="book-a-call" className="bg-success p-16 text-center mt-28 text-dark py-36">
      <h1 className="text-5xl text-center font-bold text-[#282A36] ">
        Book a FREE Consultation Call with us
      </h1> 

      <p className="text-md mb-12 text-[#282A36] mt-2">
        
        Click the button below to be directed to our Telegram where you can indicate the best date/time for a 20mins call
      </p>

      <a href="https://t.me/webencydigital">
      <button className="btn btn-secondary w-60 text-center">Booking now</button>
      </a>

<div>
      <p className="mt-16 text-sm text-[#282A36]">
        For any other issue or extra information feel free to send us a message:
        <span className="text-md font-bold"> webencydigital@gmail.com</span>
      </p>
      <p className="text-sm text-[#282A36] ">We will reply within 24 hours.</p>
</div>
    </div>
  )
}