import Nav from "./components/Nav";
import HeroAbout from "./components/HeroAbout";
import Mission from "./components/Mission";
import ProjectList from "./components/ProjectList";
import { Footer } from "./components/Footer";
import { Services } from "./components/Services";
import { Book } from "./components/Book";

function App() {
  return (
    <div className="App">
      <Nav/>
      <HeroAbout/>
      <Mission/>
      <Services/>
      <ProjectList />
      <Book/>
      <Footer />
    </div>
  );
}

export default App;
