export default function Mission() {
  return (
    <div id="about" className="hero bg-secondary min-h-screen">
      <div className="hero-content flex-col lg:flex-row-reverse py-32">
        <div>
          <h1 className="text-5xl text-[#282A36] font-bold">
            Our Mission
          </h1>
          <p className="py-6 sm:pr-32 mt-4 text-lg text-[#282A36] ">
          At Webency, we are passionate about crafting digital success stories. Our mission is to help businesses like yours achieve their full potential by creating stunning websites and apps, implementing effective digital marketing strategies, and providing data-driven insights to increase your bottom line.
<br /><br />
We understand that every business is unique, which is why we take a personalized approach to every project. Our team of experts works closely with you to understand your goals, objectives, and target audience, ensuring that our solutions are tailored to your specific needs. We are passionate about what we do, and we take pride in our ability to deliver exceptional results.
<br /><br />
Our goal is to help you succeed in the digital world and make more money in the process. Whether you're just starting out or looking to take your business to the next level, we are here to help. Join us on our passionate mission to craft your digital success story. Let's create something truly unique and powerful together.
          
<br /><br />
          </p>
          <a className="btn btn-[#282A36]" href="https://t.me/webencydigital" >
            Get started today
          </a>
        </div>
      </div>
    </div>
  );
}
