import web from "../media/web.png";

export default function HeroAbout() {
  return (
    <div className="hero bg-base-200 min-h-screen pb-24">
      <div className="hero-content flex-col lg:flex-row-reverse">
        <img src={web} className="max-w-xs mt-16" alt="myself Laura" />
        <div>
          <h1 className="text-4xl text-success font-bold mt-12">
            Web Design & Software Development Agency
          </h1>
          <p className="py-6 sm:pr-32 mt-4 text-lg">
          At Webency, we are dedicated to helping companies and individuals thrive in the digital world. Our expertise in web design, software development, digital marketing, and data analytics allows us to provide tailored solutions to help you achieve your online goals and reach your full potential. 
          <br />  <br />
          Ready to go to the next level?
          <br /> <br />
          </p>
          <a className="btn btn-success" href="https://t.me/webencydigital">
          Book a FREE consultation!
          </a>  
        </div>
      </div>
    </div>
  );
}
